import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Calendar } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  className?: string
  cta?: ButtonProps
  description?: string
  email?: string
  end_date?: string
  languageCode: string
  start_date?: string
  thumbnail?: ImageProps
  title?: string
}

export const Competition = memo(function Competition({
  className,
  cta,
  description,
  end_date,
  languageCode,
  start_date,
  thumbnail,
  title,
}: Props) {
  let end
  let start

  if (start_date && typeof window !== 'undefined') {
    start = new window.Date(start_date)
    if (end_date) {
      end = new window.Date(end_date)
    }
  }

  return (
    <Container className={className}>
      <Background>
        {thumbnail ? (
          <LazyLoadComponent>
            <Image {...thumbnail} />
          </LazyLoadComponent>
        ) : null}
      </Background>

      <Wrapper>
        {title ? <Title>{title}</Title> : null}
        {start_date ? (
          <Date dial={4} row>
            <Calendar />

            {end_date
              ? `${start?.toLocaleDateString(languageCode, {
                  day: 'numeric',
                })}-${end?.toLocaleDateString(languageCode, {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })}`
              : start?.toLocaleDateString(languageCode, {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })}
          </Date>
        ) : null}

        {description ? (
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        ) : null}

        {cta ? <CTA {...cta} variant="outline" /> : null}
      </Wrapper>
    </Container>
  )
})

const Container = styled.div`
  display: none;
  align-items: center;
  justify-content: stretch;
  flex-wrap: wrap;
  margin-top: 3.75rem;
  &:hover {
    img {
      transform: scale(1.1);
    }
  }
  &.visible {
    display: flex;
  }
`

const Background = styled.div`
  width: 30.903vw;
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  overflow: hidden;
  padding-bottom: 32%;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.2s ease-in-out;
  }

  @media (max-width: 1023px) {
    width: 100%;
  }

  @media (max-width: 767px) {
    padding-bottom: 66%;
  }
`

const Wrapper = styled.div`
  flex: 1;
  padding-left: 7.778vw;

  @media (max-width: 1199px) {
    padding-left: 2rem;
  }

  @media (max-width: 1023px) {
    width: 100%;
    margin-top: 1.875rem;
    padding-left: 0;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.secondaryLight};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.75rem;
  text-transform: uppercase;
`

const Date = styled(FlexBox)`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25rem;
  margin-top: 0.75rem;
  text-transform: uppercase;

  svg {
    width: auto;
    height: 0.75rem;
    fill: none;
    margin-right: 0.625rem;
    stroke: ${({ theme }) => theme.colors.variants.neutralDark4};
    stroke-width: 2;
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark5};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 2rem;
  margin-top: 2.5rem;

  @media (max-width: 1023px) {
    margin-top: 1.875rem;
  }
`

const CTA = styled(Button)`
  margin-top: 2.5rem;

  @media (max-width: 767px) {
    width: 100%;
  }
`
